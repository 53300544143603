@import 'common';

// ---
// components for this page
// none

// ---
// page styles
.cards {
	display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: space-between;
	position: relative;

	@media (min-width: $bp-site-ml) {
		flex-direction: row;
		align-items: stretch;
	}
}

	.card {
		width: 100%;
		max-width: 403px;

		&--login {

			@media (max-width: $bp-site-ml) {
				border-bottom: $component-border-width $component-border-style $component-border-color;
				margin-bottom: rem($elements-spacing-lg);
				padding-bottom: rem($elements-spacing-lg);
			}
		}

		@media (min-width: $bp-site-ml) {
			max-width: 45%;
		}

		@include media-breakpoint-up(lg) {
			max-width: 403px;
		}
  }
  

// ---
// forgot password
.forgot {
  margin-top: 1rem;
}


// ---
// theme overwrites
@if (variable-exists(page-login-overwrite)) {
  @if ($page-login-overwrite) {
    @include login;
  }
}
