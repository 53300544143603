.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media (min-width: 767px) {
  .cards {
    flex-direction: row;
    align-items: stretch;
  }
}

.card {
  width: 100%;
  max-width: 403px;
}

@media (max-width: 767px) {
  .card--login {
    border-bottom: 1px solid #ecedee;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

@media (min-width: 767px) {
  .card {
    max-width: 45%;
  }
}

@media (min-width: 1024px) {
  .card {
    max-width: 403px;
  }
}

.forgot {
  margin-top: 1rem;
}
